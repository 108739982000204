const doGet = ({ chatServiceUrl, token, path, raw }) => {
  return doFetch({ chatServiceUrl, token, path, method: 'GET', raw })
}

const doPost = ({ chatServiceUrl, token, path, body, raw }) => {
  return doFetch({ chatServiceUrl, token, path, method: 'POST', body, raw })
}

const doFetch = ({ chatServiceUrl, token, path, method, body, raw }) => {
  return fetch(`https://${chatServiceUrl}${path}`, {
    method,
    headers: {
      'Content-Type': 'application/vnd.api+json',
      Authorization: `Bearer ${token}`,
      Pragma: 'no-cache'
    },
    body: JSON.stringify(body)
  }).then((response) => {
    if (raw) {
      return response
    }
    return response.json()
  })
}
const postMessage = (command, params) => {
  window.parent.postMessage(
    {
      command,
      params
    },
    '*'
  )
}

const sendEvent = ({ conversationID, event_attributes, token, chatServiceUrl }) => {
  return doPost({
    path: `/conversations/${conversationID}/events`,
    token,
    chatServiceUrl,
    body: {
      data: {
        type: 'conversation_events',
        attributes: event_attributes
      }
    }
  })
}
const getConversation = ({ conversationID, chatServiceUrl, token }) => {
  return doGet({
    chatServiceUrl,
    token,
    path: `/conversations/${conversationID}?include=participants,tags`
  })
}

const loadConversations = ({
  token,
  chatServiceUrl,
  searchText,
  sortOrder,
  searchFilter,
  page,
  pageSize,
  searchType
}) => {
  const path = '/conversations?'
  const params = new URLSearchParams()
  if (searchText) {
    params.set('filter[search]', searchText)
  }
  if (searchType) {
    params.set('filter[search_type]', searchType)
  }
  switch (searchFilter) {
    case 'mine_open':
      params.set('filter[status]', 'open')
      params.set('filter[participants]', 'mine')
      break
    case 'mine':
      params.set('filter[participants]', 'mine')
      break
    case 'all_open':
      params.set('filter[status]', 'open')
      params.set('filter[participants]', 'all')
      break
    case 'all':
      params.set('filter[participants]', 'all')
      params.set('filter[status]', 'not_prompt')
      break
    case 'unclaimed':
      params.set('filter[participants]', 'unclaimed')
      params.set('filter[status]', 'not_prompt')
      break
    case 'my_notes':
      params.set('filter[notes]', 'mine')
      params.set('filter[participants]', 'mine')
      break
    case 'my_unresolved_notes':
      params.set('filter[notes]', 'unresolved')
      params.set('filter[participants]', 'mine')
      break
  }

  params.set('sort_order', sortOrder)
  params.set('include', 'read_receipt')
  if (page && pageSize) {
    params.set('page[number]', page)
    params.set('page[size]', pageSize)
  }

  return doGet({
    chatServiceUrl,
    token,
    path: path + params.toString()
  })
}

const getConversationEvents = ({ conversationID, token, chatServiceUrl }) => {
  return doGet({
    path: `/conversations/${conversationID}/events`,
    chatServiceUrl,
    token
  })
}

const createConversationWithAgent = ({
  first_message,
  prompt,
  chatServiceUrl,
  token,
  url,
  referrer,
  route_to_user_id,
  page_title
}) => {
  return doPost({
    path: '/conversations/visitor_initiated',
    chatServiceUrl,
    token,
    body: {
      data: {
        type: 'conversations',
        attributes: {
          body: first_message,
          kind: 'chat',
          prompt,
          start_url: url,
          referrer,
          page_title,
          route_to_user_id
        }
      }
    }
  })
}

const getSlotPickerParams = ({ event_id, token, chatServiceUrl }) => {
  return doPost({ path: `/calendar_drops/${event_id}`, chatServiceUrl, token })
}

const handleButtonPush = ({ buttonID, conversationID, token, chatServiceUrl }) => {
  return doPost({
    chatServiceUrl,
    token,
    path: '/conversations:button_push',
    body: {
      data: {
        attributes: {
          button_id: buttonID,
          conversation_id: conversationID
        }
      }
    }
  })
}

const createCalendarEvent = ({ event_id, start, end, startTimestamp, endTimestamp, token, chatServiceUrl }) => {
  return doPost({
    raw: true,
    chatServiceUrl,
    token,
    path: '/calendar_events',
    body: {
      data: {
        attributes: {
          scheduled_start: start,
          scheduled_end: end,
          scheduled_start_timestamp: startTimestamp,
          scheduled_end_timestamp: endTimestamp
        },
        relationships: {
          calendar_drop: {
            data: {
              type: 'conversation_events',
              id: event_id
            }
          }
        }
      }
    }
  }).then((response) => {
    if (response.status >= 400 && response.status < 500) {
      return false
    } else {
      return response.json()
    }
  })
}

const registerTextReminder = ({ calendarDropEventID, phone, reminderTime, token, chatServiceUrl }) => {
  return doPost({
    token,
    chatServiceUrl,
    path: '/calendar_events/text_reminder',
    body: {
      data: {
        attributes: {
          phone,
          reminder_time: reminderTime
        },
        relationships: {
          calendar_drop: {
            data: {
              type: 'conversation_events',
              id: calendarDropEventID
            }
          }
        }
      }
    }
  })
}

const registerEmailReminder = ({ calendarDropEventID, email, token, chatServiceUrl }) => {
  return doPost({
    token,
    chatServiceUrl,
    path: '/calendar_events/email_reminder',
    body: {
      data: {
        attributes: { email },
        relationships: {
          calendar_drop: {
            data: {
              type: 'conversation_events',
              id: calendarDropEventID
            }
          }
        }
      }
    }
  })
}

const finishConversationRating = ({ rating_event_id, rating, ratingType, token, chatServiceUrl }) => {
  return doPost({
    token,
    chatServiceUrl,
    path: '/conversation_rating_events',
    body: {
      data: {
        attributes: {
          ratingType,
          rating
        },
        relationships: {
          rating_drop: {
            data: {
              type: 'conversation_events',
              id: rating_event_id
            }
          }
        }
      }
    }
  })
}

const requestBotByFlowId = ({ flow_id, token, chatServiceUrl, page_url, referrer, page_title }) => {
  console.log('attempting to request bot flow: ', flow_id)
  return doPost({
    token,
    chatServiceUrl,
    path: '/conversations',
    body: {
      data: {
        type: 'conversations',
        attributes: {
          body: '',
          kind: 'bot_trigger',
          data: {
            flow_id: flow_id
          },
          status: 'prompt',
          start_url: page_url,
          referrer,
          page_title
        }
      }
    }
  })
}

const requestBotById = ({ botID, token, chatServiceUrl, page_url, page_title, referrer, preview, submissionID }) => {
  return doPost({
    path: '/conversations',
    token,
    chatServiceUrl,
    body: {
      data: {
        type: 'conversations',
        attributes: {
          body: '',
          kind: 'bot_trigger',
          data: {
            bot_id: botID,
            submission_id: submissionID
          },
          status: 'prompt',
          start_url: page_url,
          page_title,
          referrer,
          preview: preview || false
        }
      }
    }
  })
}

const sendChat = ({ conversationID, body, data, token, chatServiceUrl }) => {
  return sendEvent({
    conversationID,
    event_attributes: {
      body,
      kind: 'chat',
      data: data || {}
    },
    token,
    chatServiceUrl
  })
}

const sendTypingEvent = ({ conversationID, token, chatServiceUrl }) => {
  return sendEvent({
    conversationID,
    event_attributes: {
      body: '',
      kind: 'type'
    },
    token,
    chatServiceUrl
  })
}

const sendHaltTypingEvent = ({ conversationID, token, chatServiceUrl }) => {
  return sendEvent({
    conversationID,
    event_attributes: {
      body: '',
      kind: 'halt'
    },
    token,
    chatServiceUrl
  })
}
const loadWelcomePacket = ({ chatServiceUrl, token, pageURL, packetCode, referrer }) => {
  const params = new URLSearchParams()
  params.append('filter[referrer]', referrer)
  params.append('filter[page_url]', pageURL)
  params.append('filter[packet_code]', packetCode)
  return doGet({
    path: '/welcome_packet?' + params.toString(),
    chatServiceUrl,
    token,
    raw: true
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json()
      }
      console.log('non 200 returned:', response.status)
      return false
    })
    .then((welcome_packet) => {
      if (!welcome_packet) {
        return false
      }
      return welcome_packet.data.attributes
    })
}
const setLocalItem = (k, v) => {
  try {
    var item = { value: v, expires: Date.now() + 1000 * 60 * 60 }
    window.localStorage.setItem(k, JSON.stringify(item))
    setItem(k, v)
  } catch (e) {
    // pass
  }
}

const setItem = (k, v) => {
  try {
    window.sessionStorage.setItem(k, v)
  } catch (e) {
    // pass
  }
}

const getLocalItem = (k) => {
  try {
    var item = window.localStorage.getItem(k)
    if (!item) {
      return null
    }
    var visitObj = JSON.parse(item)
    if (visitObj['expires'] < Date.now()) {
      window.localStorage.removeItem(k)
      return null
    }
    if (!window.sessionStorage.getItem(k)) {
      window.localStorage.removeItem(k)
      setLocalItem(k, visitObj['value'])
      return visitObj['value']
    }
    return visitObj['value']
  } catch (e) {
    // pass
  }
  return null
}

const getItem = (k) => {
  try {
    return window.sessionStorage.getItem(k)
  } catch (e) {
    // pass
  }
  return null
}

// const removeLocalItem = (k) => {
//   try {
//     window.localStorage.removeItem(k)
//   } catch (e) {
//     // pass
//   }
// }

const removeItem = (k) => {
  try {
    window.sessionStorage.removeItem(k)
  } catch (e) {
    // pass
  }
}
const crypto = window.crypto || window.msCrypto
const generateToken = (byte_count) => {
  const array_values = new Uint8Array(byte_count)
  crypto.getRandomValues(array_values)
  const hex = []

  for (let j = 0; j < array_values.length; j++) {
    const num = array_values[j]
    let i = num.toString(20)
    if (i.length < 2) {
      i = '0' + i
    }
    hex.push(i)
  }

  return hex.join('')
}
const getVisitId = (packet_code) => {
  if (!packet_code) return undefined
  let visitID = getLocalItem(`visit-id-${packet_code}`)
  if (!visitID) {
    visitID = generateToken(16)
    setLocalItem(`visit-id-${packet_code}`, visitID)
  }
  return visitID
}

const getUnwantedEmails = ({ chatServiceUrl, token }) => {
  if (!getItem('unwanted-emails')) {
    doGet({
      path: '/unwanted',
      chatServiceUrl,
      token
    }).then((response) => {
      postMessage('unwanted_emails', response.data.attributes)
    })
  }
}

const getFormFill = ({ chatServiceUrl, token, form_id, pageURL, pageTitle, visitID }) => {
  const params = new URLSearchParams()
  params.append('page_url', pageURL)
  params.append('page_title', pageTitle)
  params.append('visit_id', visitID)
  doGet({
    path: `/forms/${form_id}/fill?${params.toString()}`,
    chatServiceUrl,
    token
  }).then((response) => {
    postMessage('form_fill', { id: form_id, form_fill: response.data.attributes })
  })
}

export {
  doPost,
  setItem,
  getItem,
  getVisitId,
  removeItem,
  generateToken,
  getConversation,
  requestBotById,
  requestBotByFlowId,
  createCalendarEvent,
  finishConversationRating,
  getSlotPickerParams,
  handleButtonPush,
  getConversationEvents,
  loadConversations,
  sendChat,
  sendTypingEvent,
  sendHaltTypingEvent,
  registerTextReminder,
  registerEmailReminder,
  createConversationWithAgent,
  loadWelcomePacket,
  postMessage,
  getUnwantedEmails,
  getFormFill
}
